import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import useInformation from "../Hooks/useInformation";

const Notice = () => {
  const [info, isLoading] = useInformation();

  return (
    <div className="dark:bg-gray-700 py-6">
      <p className="dark:text-sky-300 text-center font-bold text-3xl  -mt-2">
        Notice
      </p>
      {isLoading === true ? (
        <div className="flex justify-center my-20">
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Notice</th>

                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {info
                .filter((item) => item.role === "notice")
                .map((item, index) => (
                  <tr key={index}>
                    <th>1</th>
                    <td>
                      <a
                        className="hover:text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item?.details}
                      >
                        {item?.title}
                      </a>{" "}
                    </td>

                    <td className="avatar">
                      <div className="w-24 rounded-xl hover:cursor-pointer">
                        <img src={item?.imageUrl} alt="" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Notice;
