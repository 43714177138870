import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../Assets/image/logo.png";
const Footer = () => {
  return (
    <div>
      <footer className="mt-auto w-full bg-gray-100 dark:bg-gray-900 py-16">
        <div className="md:px-12 lg:px-28">
          <div className="container m-auto space-y-6 text-gray-600 dark:text-gray-300">
            <img
              src={logo}
              alt="polliprottasha logo"
              className="m-auto w-40 rounded-full "
            />
            <ul className="flex flex-col items-center justify-center gap-4 py-4 sm:flex-row sm:gap-8">
              <li>
                <NavLink to="/" className="hover:text-primary">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/project" className="hover:text-primary">
                  Project
                </NavLink>
              </li>
              <li>
                <NavLink to="/work" className="hover:text-primary">
                  What We Do
                </NavLink>
              </li>
              <li>
                <NavLink to="/history" className="hover:text-primary">
                  About us
                </NavLink>
              </li>
            </ul>
            <div className="m-auto flex w-max items-center justify-between space-x-4">
              <a href="tel:+8801618700030" aria-label="call">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="m-auto w-5"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  ></path>
                </svg>
              </a>
              <a
                href="mailto:polliprottasha21@gmail.com"
                aria-label="send mail"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="m-auto w-5"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/polliprottasha21/"
                title="facebook"
                target="blank"
                aria-label="facebook"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="m-auto w-5"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@polliprottasha"
                title="linkedin"
                target="blank"
                aria-label="linkedin"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="m-auto w-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.268,6.669c-0.262-0.973-1.031-1.738-2.022-2.002C19.433,4.3
    15.576,4,12,4c-3.576,0-7.433,0.3-9.246,0.667C2.763,4.931,2.094,5.696,1.832,6.669
    1.532,7.616,1.5,9.363,1.5,12s0.031,4.384,0.332,5.331c0.262,0.973,0.931,1.738,1.832,2.002
    C4.567,19.7,8.424,20,12,20s7.433-0.3,9.246-0.667c0.991-0.264,1.76-1.029,2.022-2.002
    0.301-0.947,0.332-2.694,0.332-5.331S23.569,7.616,23.268,6.669z M9.75,15.084V8.916l5.916,3.584
    L9.75,15.084z"
                  />
                </svg>
              </a>
            </div>

            <div className="text-center">
              <span className="text-sm tracking-wide">
                Copyright © Polliprottasha | All right reserved
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary text-sm tracking-wide ml-2"
                href="https://protfolio-tarek-siddique-nabil.vercel.app/"
              >
                Developed By:
                <span className="hover:text-yellow-300">
                  Tarek Siddique Nabil
                </span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
