import React from 'react';
import Card from './Card';
import Carousel from './Carousel';
import Content from './Content';
import Contributor from './Contributor';
import Hero from './Hero';
import Success from './Success';

const Home = () => {
    return (
        <div className='relative overflow-x-hidden'>
            <Hero/>
            <Carousel/>
            <Content/>
            <Card/>
            <Success/>
            <Contributor/>
            
        </div>
    );
};

export default Home;