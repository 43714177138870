import  { useEffect, useState } from "react";

const useInformation =()=>{
    const [info,setInfo] =useState([])
    const [isLoading, setLoading] = useState(true);
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_SECRET_SERVER_SIDE}/data`)
        .then((res=> res.json()))
        .then((data)=>{
            setInfo(data);
            setLoading(false);
        })
    },[])
    return [info,isLoading]
}
export default useInformation;
