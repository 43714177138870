import {  Card, Image, Modal } from "@nextui-org/react";
import React, { useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import useInformation from "../Hooks/useInformation";
const Gallery = () => {
  const [info, isLoading] = useInformation();
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState();
  const combinedFunction = (e) => {
    setImage(e);
    handler();
  };
  const handler = () => setVisible(true);
  const closeHandler = () => {
    setImage("");
    setVisible(false);
  };
  return (
    <div className="dark:bg-gray-700 py-6">
      <p className="dark:text-sky-300 text-center font-bold text-3xl  -mt-2">
        Gallery
      </p>
      {isLoading === true ? (
        <div className="flex justify-center my-20">
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      ) : (
        <div
          name="main"
          className=" grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-items-center  "
        >
          {info
            // .filter((item) => item.role === "gallery")
            .map((item, index) => (
              <div key={index} className="m-4">
                <Card
                  className={`${
                    visible === true ? "opacity-30" : "opacity-100"
                  }`}
                  isPressable
                  onPress={() => combinedFunction(item.imageUrl)}
                >
                  <Card.Image
                    src={item.imageUrl}
                    objectFit="cover"
                    width={300}
                    height={340}
                    alt="Card image background"
                  />
                </Card>
                <Modal
                  noPadding
                  open={visible}
                  onClose={closeHandler}
                  style={{ backgroundColor: "transparent" }}
                >
                  <Modal.Header
                    css={{
                      position: "absolute",
                      zIndex: "$1",
                      top: 5,
                      right: 8,
                    }}
                  >
                    <button
                      className="bg-none"
                      size="sm"
                      onClick={closeHandler}
                    >
                      <div className="flex">
                        <p>Close</p> 
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      </div>
                     
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <Image src={image} width="100%" height="95%" />
                  </Modal.Body>
                </Modal>
              </div>
            ))}
          {/* The button to open modal */}

          {/* Put this part before </body> tag */}
        </div>
      )}
    </div>
  );
};

export default Gallery;
