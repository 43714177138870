import React from "react";
import pic1 from "../../Assets/image/chairman.jpg";
// import pic2 from "../../Assets/image/director.jpg";
import useInformation from "../Hooks/useInformation";

import { CirclesWithBar } from "react-loader-spinner";

const Management = () => {
  const [info, isLoading] = useInformation();
  return (
    <>
      <div className="dark:bg-gray-700 py-6">
        <p className="dark:text-sky-300 text-center font-bold text-3xl  -mt-2">
          Governing Body
        </p>
        {isLoading === true ? (
          <div className="flex justify-center my-20">
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              outerCircleColor=""
              innerCircleColor=""
              barColor=""
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="table w-full">
              {/* head */}
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1</th>
                  <td>Habibur Rahman Jony </td>
                  <td>Chairmaen</td>
                  <td className="avatar">
                    <div className="w-24 rounded-xl hover:cursor-pointer">
                      <a
                        className="hover:text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.polliprottasha.org/static/media/chairman.43518a094dd8d501c147.jpg"
                      >
                        {" "}
                        <img src={pic1} alt="" />
                      </a>
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <th>2</th>
                  <td>Sheikh Faruque ahammad </td>
                  <td>Director</td>
                  <td className="avatar">
                    <div className="w-24 rounded-xl hover:cursor-pointer">
                      <a
                        className="hover:text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.polliprottasha.org/static/media/director.460a0dcc6c0dfe540b2c.jpg"
                      >
                        {" "}
                        <img src={pic2} alt="" />
                      </a>
                    </div>
                  </td>
                </tr> */}
                {info
                  .filter((item) => item.role === "employers")
                  .map((item, index) => (
                    <tr>
                      <th>{index + 3}</th>
                      <td>{item?.name}</td>
                      <td>{item?.grade}</td>
                      <td className="avatar">
                        <div className="w-24 rounded-xl hover:cursor-pointer">
                          <a
                            className="hover:text-blue-500"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={item?.imageUrl}
                          >
                            {" "}
                            <img src={item?.imageUrl} alt="" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* <div className="flex flex-wrap  justify-center gap-x-16  mx-3">
        <div>
          <p className="dark:text-slate-50 font-bold text-center text-xl border p-2 rounded-xl border-cyan-400 hover:bg-slate-500">
            Chairmen
          </p>
          <br />
          <div className="card lg:w-96 h-auto card-compact  bg-base-100 shadow-xl">
            <figure>
              <img
                style={{ width: "300px", height: "300px" }}
                src={pic1}
                alt="Album"
              />
            </figure>
            <div className="card-body group">
              <h2 className="card-title"> Sheikh Faruque ahammad</h2>
              <span className="text-gray-400 group-hover:text-gray-800">
                Executive Director
              </span>
              <p>
                From 1971 Bangladesh is facing many challenges including social,
                environment and economical aspect. It's education, health, live
                hood women empowerment and socio-economic sector is steel
                vulnerable. Polliprottasha continues to staff are very careful
                about their respective responsibilities to fill up
                organizational objectives.
              </p>
              <div className="card-actions justify-end">
                <Link to="/chairman" className="btn btn-primary btn-xs">
                  More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="dark:text-slate-50 font-bold text-xl border p-2 text-center rounded-xl border-cyan-400 hover:bg-slate-500">
            Executive Director
          </p>
          <br />
          <div className="card lg:w-96 h-auto card-compact bg-base-100 shadow-xl">
            <figure>
              <img
                style={{ width: "300px", height: "300px" }}
                src={pic2}
                alt="Album"
              />
            </figure>
            <div className="card-body group">
              <h2 className="card-title"> Sheikh Faruque ahammad</h2>
              <span className="text-gray-400 group-hover:text-gray-800">
                Executive Director
              </span>
              <p>
                Sheikh Faruque ahammad has been working in development field
                over fifteen years tenures . He gained an ample experience in
                diverse development paradigms; like Microfinance (MF)
                management, result based program management (RBM), Project life
                cycle management (PCM) and value chain Project facilitation
                (VCF) approach.
              </p>
              <div className="card-actions justify-end">
                <Link to="/director" className="btn btn-primary btn-xs">
                  More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading === true ? (
        <div className="flex justify-center my-20">
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-x-16 xl:my-16 md:my-8 mx-3 my-4 gap-y-4">
          {info
            .filter((item) => item.role === "employers")
            .map((item, index) => (
              <div
                key={index}
                className="group w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 border-gray-700 border-2  dark:border-sky-300"
              >
                <div className="flex justify-center">
                  <img
                    style={{ width: "280px", height: "280px" }}
                    className="rounded-3xl mt-6 scale-90 group-hover:rounded-xl transition ease-out duration-300 group-hover:scale-100"
                    src={item?.imageUrl}
                    alt={item?._id}
                  />
                </div>
                <div className="px-5 pb-5 mt-3">
                  <div>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-cyan-400  capitalize ">
                      {item?.name}
                    </h5>
                  </div>
                  <div className="flex items-center justify-between ">
                    <span className="text-xl font-semibold text-gray-900 dark:text-white capitalize">
                      {item?.grade}
                    </span>
                    {item?.phone ? (
                      <p className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        {item?.phone}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )} */}
    </>
  );
};

export default Management;
