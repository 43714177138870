import React, { useRef, useState } from "react";
import facebook from "../../Assets/image/facebook.png";
import twitter from "../../Assets/image/twitter.png";
import instagramDark from "../../Assets/image/instagram (1).png";
import youtube from "../../Assets/image/youtube.png";

import { CirclesWithBar } from "react-loader-spinner";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const number = e.target.phone.value;
    const message = e.target.textarea.value;
    const data = {
      name: name,
      email: email,
      phone: number,
      message: message,
      role: "message",
    };
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SECRET_SERVER_SIDE}/data`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await response.json();
      if (json) {
        setLoading(false);
        alert("Added successfully");
        e.target.name.value = "";
        e.target.email.value = "";
        e.target.phone.value = "";
        e.target.textarea.value = "";
      }
    } catch (error) {
      alert("SOMETHING ERROR");
    }
  };

  let div;

  if (loading) {
    div = (
      <div className="absolute inset-0 flex justify-center items-center">
        <CirclesWithBar
          height="100"
          width="100"
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          outerCircleColor=""
          innerCircleColor=""
          barColor=""
          ariaLabel="circles-with-bar-loading"
        />
      </div>
    );
  }

  return (
    <section className="bg-gray-100 relative">
      {div}
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5 bg-white dark:bg-gray-500 rounded-xl">
          <div className="lg:col-span-2 lg:py-12 shadow-lg rounded-xl bg-sky-300 dark:bg-gray-800 dark:text-slate-50">
            <div className=" p-3">
              <p className="text-xl font-semibold text-center">
                Contact Information
              </p>
              <p className="">
                Fill up the form and our Team will get back to you within 24
                hours{" "}
              </p>
              <div className="flex items-center mt-6 ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-10 h-10 ml-5"
                >
                  <path d="M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" />
                  <path
                    fillRule="evenodd"
                    d="M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z"
                    clipRule="evenodd"
                  />
                </svg>
                <a href="tel:+8801618700030">
                  <button className="font-semibold">+8801618700030</button>
                </a>
              </div>
              <div className="flex items-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gzmgulpl.json"
                  stroke="75"
                  trigger="loop-on-hover"
                  style={{ width: "80px", height: "65px" }}
                ></lord-icon>
                <a href="mailto:polliprottasha21@gmail.com">
                  <button className="font-semibold">info@gmail.com</button>
                </a>
              </div>
              <div className="flex items-center ml-2">
                <lord-icon
                  src="https://cdn.lordicon.com/fihkmkwt.json"
                  stroke="75"
                  trigger="hover"
                  style={{ width: "80px", height: "65px" }}
                ></lord-icon>
                <a
                  href="https://www.google.com/maps/place/Polli+Prottasha/@24.753503,90.5742,132m/data=!3m1!1e3!4m6!3m5!1s0x3756577bb5a29f75:0x3ea41fe5ec720f76!8m2!3d24.7498312!4d90.5732323!16s%2Fg%2F11r_mhddy7?hl=en"
                  className="font-semibold hover:cursor-pointer"
                  for="checkbox_id"
                >
                  Location
                </a>
              </div>
              <div className="flex justify-center gap-4">
                <a
                  type="button"
                  className="p-1 border border-gray-700 hover:border-slate-50 rounded-full"
                  href="https://www.facebook.com/polliprottasha21/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={facebook}
                    alt={facebook}
                  />
                </a>
                <a
                  type="button"
                  className="p-1 border border-gray-700 hover:border-slate-50 rounded-full"
                  href="https://www.youtube.com/@polliprottasha"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={youtube}
                    alt={youtube}
                  />
                </a>
                <a
                  type="button"
                  className="p-1 border border-gray-700 hover:border-slate-50 rounded-full"
                  href="https://www.twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={twitter}
                    alt={twitter}
                  />
                </a>
                <a
                  type="button"
                  className="p-1 border border-gray-700 hover:border-slate-50 rounded-full"
                  href="https://www.youtube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={instagramDark}
                    alt={instagramDark}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="rounded-lg  p-8  lg:col-span-3 lg:p-12">
            <form
              ref={form}
              action="POST"
              onSubmit={handleSubmit}
              className="space-y-4"
            >
              <div>
                <label className="sr-only" for="name">
                  Name
                </label>
                <input
                  required
                  className="w-full ring-2 rounded-lg ring-gray-300 focus:outline-none focus:ring focus:ring-gray-900 p-3 text-sm"
                  placeholder="Name"
                  type="text"
                  id="name"
                  name="name"
                />
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="sr-only" for="email">
                    Email
                  </label>
                  <input
                    className="w-full ring-2 rounded-lg ring-gray-300 focus:outline-none focus:ring focus:ring-gray-900 p-3 text-sm"
                    placeholder="Email address"
                    type="email"
                    id="email"
                    name="email"
                    required
                  />
                </div>

                <div>
                  <label className="sr-only" for="phone">
                    Phone
                  </label>
                  <input
                    className="w-full ring-2 rounded-lg ring-gray-300 focus:outline-none focus:ring focus:ring-gray-900 p-3 text-sm"
                    placeholder="Phone Number"
                    type="tel"
                    id="phone"
                    name="phone"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="sr-only" for="message">
                  Message
                </label>

                <textarea
                  className="w-full ring-2 rounded-lg ring-gray-300 focus:outline-none focus:ring focus:ring-gray-900 p-3 text-sm"
                  placeholder="Message"
                  rows="8"
                  id="message"
                  name="textarea"
                  required
                ></textarea>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <p className="text-center text-2xl font-bold underline mb-4">Map</p>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 0,
          paddingBottom: "75%",
        }}
      >
        <iframe
          className="rounded-2xl"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d296.9315195217122!2d90.57309550734026!3d24.749831200000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3756577bb5a29f75%3A0x3ea41fe5ec720f76!2sPolli%20Prottasha!5e1!3m2!1sen!2sbd!4v1677672233236!5m2!1sen!2sbd"
          title="Google Map"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
