import { Card } from "@nextui-org/react";
import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import useInformation from "../Hooks/useInformation";

const Career = () => {
  const [info, isLoading] = useInformation();
  return (
    <div>
      <p className="dark:text-slate-50 text-3xl font-bold text-center my-3">
        Career
      </p>
      {isLoading===true ?
      <div className="flex justify-center my-20">
      <CirclesWithBar
        height="100"
        width="100"
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        outerCircleColor=""
        innerCircleColor=""
        barColor=""
        ariaLabel="circles-with-bar-loading"
      /></div>:
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-items-center gap-y-4 my-10 ">
        {info
          .filter((item) => item.role === "career")
          .map((item, index) => (
            <Card
              css={{ width: "320px", height: "320px" }}
              isPressable
              className="group "
            >
              <a
                href={item?.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Card.Image
                  className="group-hover:scale-105"
                  src={item?.imageUrl}
                  objectFit="contain"
                  css={{ width: "320px", height: "320px" }}
                  alt="Card image background"
                />
              </a>
            </Card>
          ))}
      </div>}
    </div>
  );
};

export default Career;
