import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../Assets/image/logo.png";
const Header = () => {
  const [theme, setTheme] = useState(() => localStorage.getItem("theme"));

  const [isDarkMode, setIsDarkMode] = useState(() =>
    localStorage.getItem("theme") === "dark" ? true : false
  );
  const handleToggle = () => {
    setIsDarkMode(!isDarkMode);
    setTheme(isDarkMode ? "light" : "dark ");
  };
  if (isDarkMode) {
    localStorage.setItem("theme", "dark");
  } else {
    localStorage.setItem("theme", "light");
  }
  useEffect(() => {
    const storageTheme = localStorage.getItem("theme");
    if (theme === "dark" || storageTheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);
  return (
    <div>
      <div className=" w-full border-b dark:border-slate-50 border-gray-800">
        <nav className=" bg-sky-300 dark:bg-gray-800">
          <div className="container m-auto px-6  lg:px-7">
            <div className="flex flex-wrap items-center justify-between lg:justify-around py-6 gap-6 lg:py-4 lg:gap-0 relative">
              <input
                type="checkbox"
                name="toggle_nav"
                id="toggle_nav"
                className="peer hidden"
              />
              <div className="w-full flex justify-between lg:w-max lg:px-0">
                <img
                  src={logo}
                  className="w-24 rounded-full    "
                  alt="polliprottasha logo"
                />

                <div className="flex items-center lg:hidden max-h-10">
                  <label
                    role="button"
                    for="toggle_nav"
                    aria-label="humburger"
                    id="hamburger"
                    className="relative z-40 px-2 py-3 sm:-mr-6"
                  >
                    <div
                      id="line"
                      className="m-auto h-0.5 w-6 rounded bg-sky-900 dark:bg-gray-300 transition duration-300"
                    ></div>
                    <div
                      id="line2"
                      className="m-auto mt-2 h-0.5 w-6 rounded bg-sky-900 dark:bg-gray-300 transition duration-300"
                    ></div>
                  </label>
                </div>
              </div>

              <label
                role="button"
                for="toggle_nav"
                className="fixed w-full z-30 h-full m-auto bg-gray-700 bg-opacity-40 hidden peer-checked:block lg:peer-checked:hidden"
              ></label>

              <div
                className="flex z-50 flex-col lg:flex-row justify-between 
                    items-center gap-y-4 p-6 bg-white dark:bg-gray-800 lg:w-9/12
                    lg:gap-y-4 lg:p-0 
                    lg:bg-transparent fixed top-0 -left-full transition-all duration-500 peer-checked:left-0 max-w-sm h-full 
                    lg:left-0 lg:h-auto w-4/5 lg:max-w-none lg:relative lg:first-letter:top-0"
              >
                <div className="flex lg:hidden w-full pb-5">
                  <img
                    src={logo}
                    className="w-20 rounded-full contrast-200 border-2  dark:border-slate-50"
                    alt="polliprottasha logo"
                    width="144"
                    height="68"
                  />
                </div>
                <div className="block w-full h-full lg:h-auto">
                  <ul className="space-y-8 tracking-wide font-medium lg:flex lg:space-y-0 xl:gap-x-2 ">
                    <li>
                      <NavLink
                        to="/"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300 -ml-3 lg:ml-0"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="dropdown dropdown-hover -ml-4 xl:-ml-0 lg:-ml-0">
                      <label
                        tabindex="0"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300 xl:whitespace-nowrap"
                      >
                        About
                      </label>
                      <ul
                        tabindex="0"
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                      >
                        <li>
                          <NavLink to="/history">History</NavLink>
                        </li>
                        <li>
                          <NavLink to="/management">Governing Body</NavLink>
                        </li>
                        <li>
                          <NavLink to="/organugram">Organugram</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/legal"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300 -ml-4 lg:ml-0"
                      >
                        Legal Status
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/work"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300 -ml-4 lg:ml-0"
                      >
                        What We Do
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/project"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300 -ml-4 lg:ml-0"
                      >
                        Projects
                      </NavLink>
                    </li>

                    <li className="dropdown dropdown-hover -ml-4 xl:-ml-0 lg:-ml-0">
                      <label
                        tabindex="0"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300"
                      >
                        Publication
                      </label>
                      <ul
                        tabindex="0"
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                      >
                        <li>
                          <NavLink to="/notice">Notice </NavLink>
                        </li>
                        <li>
                          <NavLink to="/career">Career</NavLink>
                        </li>

                        <li>
                          <NavLink to="/gallery">Gallery</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/contact"
                        className="btn -mt-3 bg-transparent border-0 text-black dark:text-slate-50 hover:text-yellow-300 -ml-4 lg:ml-0"
                      >
                        Contact
                      </NavLink>
                    </li>
                    <li className="lg:pl-2">
                      <button onClick={handleToggle}>
                        {isDarkMode ? (
                          <div className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 dark:text-slate-50 dark:opacity-100 transition-opacity duration-300 ease-in-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                              />
                            </svg>
                            <p className="dark:text-stone-50 lg:hidden">Dark</p>
                          </div>
                        ) : (
                          <div className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 dark:text-slate-50  dark:opacity-0  transition-opacity duration-300 ease-in-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                              />
                            </svg>
                            <p className="dark:text-stone-50 lg:hidden">
                              Light
                            </p>
                          </div>
                        )}
                      </button>
                      <span className="ml-2 sr-only">
                        {isDarkMode ? "Light Mode" : "Dark Mode"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
