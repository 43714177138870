import React from "react";
import pic from "../../Assets/image/director.jpg";
const Director = () => {
  return (
    <div>
        <p className="dark:text-sky-200 font-bold text-2xl  text-center my-4">Executive Director</p>
      <div className="grid xl:grid-cols-3  justify-items-center items-center dark:bg-gray-500 group rounded-3xl my-5 mx-2">
        <div className="xl:mt-0 mt-5">
        <img
          style={{ width: "300px", height: "300px" }}
          src={pic}
          alt="Album"
          className="rounded-3xl group-hover:rounded-xl group-hover:scale-105 transition ease-in-out duration-700"
        />
        </div>
        <div className="xl:col-span-2 my-8 px-2">
          <h2 className="card-title text-teal-300"> Sheikh Faruque ahammad</h2>
          <span className="text-gray-400 group-hover:text-gray-800 group-hover:font-bold transition duration-200">
            Executive Director
          </span>
          <p className=" dark:text-slate-50 group-hover:tracking-wide">
           <strong className="dark:text-slate-100 border-b-2 border-black">Sheikh Faruque ahammad</strong> has been working in development field over
            fifteen years tenures . He gained an ample experience in diverse
            development paradigms; like Microfinance (MF) management, result
            based program management (RBM), Project life cycle management (PCM)
            and value chain Project facilitation (VCF) approach.
            <br />
            <br />
            He has been developed number of project proposals in accordance with
            theory of Change (TOC) and logical frame work (Log Frame)
            Identification , design, Planning, implementation, capacities
            building, HR management, monitoring and control is the another of
            his expertise.
            <br />
            <br />
            He has exposure to work with a range of national and international
            development organization including; POPI –PACE Value Chain Project
            (managed by PKSF funded by IFAD), ASA- as a Regional Managers (RM).
            <br />
            <br />
            He also worked for BRAC- International, Head office, BRAC Center,
            Mohakhali, Dhaka and BRAC – Uganda- as a Senior Sector Specialist,
            Loan review unit, Microfinance program.
            <br />
            <br />
            He did B.S.S and M.S.S degree in Economics from the universities of
            Dhaka.
            <br />
            <br />
            He is a active member of DUFA.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Director;
