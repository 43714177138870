import React from "react";

const History = () => {
  return (
    <div style={{backgroundColor:"#a1d1ed"}} className="min-h-screen dark:bg-gray-700 ">
      <p className="font-extrabold text-2xl text-center py-4">
        <span className=" border-2 border-cyan-600  p-2 rounded-full  bg-white">
          Our History
        </span>
      </p>

      <div  className="w-10/12 h-10/12 mx-auto mt-10 p-8 xl:rounded-xl rounded-lg bg-white dark:bg-gray-900">
        <p className="text-gray-800  text-2xl text-justify border border-black dark:border-sky-300 rounded-xl px-9 py-5 dark:text-slate-50">
      Welcome to the Polliprottasha's website, a renown socio-economic organization in Bangladesh. Polliprottasha is a good practiced MFI which has the aims to improve the life quality of the people living at the bottom of the socio-economic pyramid. Polliprottasha focus at awareness-raising and group formation for the aiming at integrated development by establishing rights of the poor Women, Education, Healthcare, Agriculture and credit for income generation activities.
      </p>
      </div>
    </div>
  );
};

export default History;
