import React from 'react';
import pic from '../../Assets/image/chairman.jpg'
const Chairman = () => {
    return (
        <div>
        <p className="dark:text-sky-200 font-bold text-2xl  text-center my-4">Chairman</p>
      <div className="grid xl:grid-cols-3  justify-items-center items-center dark:bg-gray-500 group rounded-3xl my-5 mx-2">
        <div className="xl:mt-0 mt-5">
        <img
          style={{ width: "300px", height: "300px" }}
          src={pic}
          alt="Album"
          className="rounded-3xl group-hover:rounded-xl group-hover:scale-105 transition ease-in-out duration-700"
        />
        </div>
        <div className="xl:col-span-2  my-8 px-2">
          <h2 className="card-title text-teal-300">Habibur Rahman</h2>
          <span className="text-gray-400 group-hover:text-gray-800 group-hover:font-bold transition duration-200">
            Chairman
          </span>
          <p className=" dark:text-slate-50 group-hover:tracking-wide">
          From 1971 Bangladesh is facing many challenges including social, environment and economical aspect. It's education, health, live hood women empowerment and socio-economic sector is steel vulnerable. Polliprottasha continues to staff are very careful about their respective responsibilities to fill up organizational objectives.
            <br />
            <br />
            <strong>Polliprottasha</strong> is a socio-economic organization founded in 2003 to raise social awareness, em-power of women and poverty reduction through effective tools and programs.
            <br />
            <br/>
            Currently, we are leading Several Social welfare project and programs in Agriculture Education, health, nutrition and wash , Disaster risk reduction, climate change, livelihood and rural development sector.
            <br />
            <br />
            Microfinance is our major program for economic development inters of poverty reduction and women empowerment through income generating activities in rural area of Bangladesh . I hope , we can make a positive change for our community and beneficiaries.
            <br />
            <br />
            Above all I would like to thank to the govt. our members beneficiaries and generous donors who give their extreme support and commitment for social and economic development.
            <br />
            <br />
            Thank you.
          </p>
        </div>
      </div>
    </div>
    );
};

export default Chairman;