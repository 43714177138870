import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { Link } from "react-router-dom";
import useInformation from "../Hooks/useInformation";

const Card = () => {
  const [info, isLoading] = useInformation();
  return (
    <>
      <p className="dark:text-amber-300 text-center font-bold text-3xl -mt-2">
        What WE DO
      </p>
      {isLoading === true ? (
        <div className="flex justify-center my-20">
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      ) : (
        <div className="flex flex-wrap justify-center bg-slate-100 rounded-xl dark:bg-gray-800 mt-4 py-5 gap-y-4 gap-x-16 mx-8">
          {/* card 1*/}
          {info
            .filter((item) => item.role === "what we do")
            .slice(0, 6)
            .map((item, index) => (
              <Link
                to={`/cards/${item._id}`}
                state={{ item: item }}
                key={index}
              >
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  className="group w-72 h-56 max-w-sm bg-white border-2 border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 hover:border-t-orange-600 hover:border-b-yellow-600 hover:border-l-lime-600 hover:border-r-cyan-600 transition-all duration-500 cursor-pointer"
                >
                  <div className="flex flex-col items-center pb-10 mt-10">
                    <img
                      className="w-28 h-28 mb-3 rounded-full shadow-lg hover:scale-110 overflow-hidden group-hover:rounded group-hover:scale-110 transition ease-in-out duration-300"
                      src={item.imageUrl}
                      alt={item?.title}
                    />
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white text-center">
                      {item?.title}
                    </h5>
                  </div>
                  {index === 5 ? (
                    <p className="text-violet-400 text-end mt-1">View All</p>
                  ) : null}
                </div>
              </Link>
            ))}
        </div>
      )}
    </>
  );
};

export default Card;
