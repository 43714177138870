import React, { useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import useInformation from "../Hooks/useInformation";

const Work = () => {
  const [info, isLoading] = useInformation();
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleExpansion = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };
  return (
    <div className="">
      <div className="py-12">
        <div className="xl:container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="mb-12 space-y-2 text-center">
            <h2 className="text-3xl font-bold text-gray-800 md:text-4xl dark:text-white">
              What We Do
            </h2>
          </div>
          {isLoading === true ? (
            <div className="flex justify-center my-20">
              <CirclesWithBar
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          ) : (
            <div className="flex flex-wrap justify-center gap-5">
              {info
                .filter((item) => item.role === "what we do")
                .map((item, index) => (
                  <div key={index}>
                    {index % 2 === 0 ? (
                      <div className="group grid grid-cols-1  xl:grid-cols-2 md:grid-cols-2 justify-items-center p-4 items-center border border-gray-600 dark:border-sky-300 rounded-2xl">
                        <img
                          src={item?.imageUrl}
                          alt={item?.title}
                          className="w-80 h-80 rounded-2xl xl:mr-7 group-hover:rounded-md lg:group-hover:scale-105 transition ease-linear duration-300 md:group-hover:scale-100"
                        />
                        <div className="flex flex-col ">
                          <p className=" text-gray-600 dark:text-amber-600 font-bold text-xl italic text-center">
                            {item?.title}
                          </p>
                          <p className="text-gray-600 dark:text-gray-300 group-hover:text-lg transition-all duration-300">
                            {expandedIndexes.includes(index)
                              ? item?.details
                              : item?.details.slice(0, 400)}
                          </p>
                          <button
                            className="text-lg dark:text-violet-500 font-bold mt-2"
                            onClick={() => toggleExpansion(index)}
                          >
                            {expandedIndexes.includes(index) ? "Less" : "More"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="group grid grid-cols-1  xl:grid-cols-2 md:grid-cols-2 justify-items-center p-4 items-center border border-gray-600 dark:border-sky-300 rounded-2xl">
                        <div className="flex flex-col ">
                          <p className=" text-gray-600 dark:text-amber-600 font-bold text-xl italic text-center">
                            {item?.title}
                          </p>
                          <p className="text-gray-600 dark:text-gray-300 group-hover:text-lg transition-all duration-300">
                            {expandedIndexes.includes(index)
                              ? item?.details
                              : item?.details.slice(0, 400)}
                          </p>
                          <button
                            className="text-lg dark:text-violet-500 font-bold mt-2"
                            onClick={() => toggleExpansion(index)}
                          >
                            {expandedIndexes.includes(index) ? "Less" : "More"}
                          </button>
                        </div>
                        <img
                          src={item?.imageUrl}
                          alt={item?.title}
                          className="w-80 h-80 rounded-2xl xl:ml-7 group-hover:rounded-md lg:group-hover:scale-105 transition ease-linear duration-300 md:group-hover:scale-100"
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Work;
