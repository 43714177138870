import React from "react";

const LegalStatus = () => {
  return (
    <div className="min-h-screen ">
      <p className="font-extrabold text-2xl text-center my-5">
        <span className="border-2 border-cyan-600 p-2 rounded-full text-transparent bg-clip-text bg-gradient-to-bl from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500 ">
          Our Legal Status
        </span>
      </p>
      <div className="overflow-x-auto border-2 border-gray-700 rounded-lg ">
        <table className="table table-zebra w-full ">
          {/* <!-- head --> */}
          <thead>
            <tr className="">
              <th>SL No.</th>
              <th>Name of Concerned Dept/Institude</th>
              <th>Registration No</th>
              <th>Date of Registration</th>
            </tr>
          </thead>
          <tbody>
            {/* <!-- row 1 --> */}
            <tr>
              <th>1</th>
              <td>Department of Social Welfare, GoB</td>
              <td>Ma &#9866; 01377</td>
              <td>29-06-2003</td>
            </tr>
            {/* <!-- row 2 --> */}
            <tr>
              <th>2</th>
              <td>Micro-credit Regulatory Authority</td>

              <td>
                <a
                  className="btn btn-secondary"
                  href="http://www.mra.gov.bd/site/page/ffce375b-367e-4b12-bbb1-f2b9a1dc1cd6/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%A5%E0%A6%AE%E0%A6%BF%E0%A6%95-%E0%A6%85%E0%A6%A8%E0%A7%81%E0%A6%AE%E0%A7%8B%E0%A6%A6%E0%A6%A8-%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%AA%E0%A7%8D%E0%A6%A4-%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A4%E0%A6%BF%E0%A6%B7%E0%A7%8D%E0%A6%A0%E0%A6%BE%E0%A6%A8%E0%A7%87%E0%A6%B0-%E0%A6%A4%E0%A6%BE%E0%A6%B2%E0%A6%BF%E0%A6%95%E0%A6%BE"
                  target={"_blank"} rel="noopener noreferrer"
                >
                  Click
                </a>
              </td>
              <td>15-12-2021</td>
            </tr>
            {/* <!-- row 3 --> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LegalStatus;
