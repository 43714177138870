import Aos from "aos";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Career from "./Component/Content/Career";
import Chairman from "./Component/Content/Chairman";
import Contact from "./Component/Content/Contact";
import Director from "./Component/Content/Director";
import Gallery from "./Component/Content/Gallery";
import History from "./Component/Content/History";
import LegalStatus from "./Component/Content/LegalStatus";
import Management from "./Component/Content/Management";
import Notice from "./Component/Content/Notice";
import Organugram from "./Component/Content/Organugram";
import Project from "./Component/Content/Project";
import Work from "./Component/Content/Work";
import Footer from "./Component/Home/Footer";
import Header from "./Component/Home/Header";
import Home from "./Component/Home/Home";

import "aos/dist/aos.css";
import CardDetails from "./Component/Home/CardDetails";
const App = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="dark:bg-gray-800">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/history" element={<History />} />
          <Route path="/management" element={<Management />} />
          <Route path="/legal" element={<LegalStatus />} />
          <Route path="/work" element={<Work />} />
          <Route path="/project" element={<Project />} />
          <Route path="/chairman" element={<Chairman />} />
          <Route path="/director" element={<Director />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/organugram" element={<Organugram />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/cards/:id" element={<CardDetails />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
