import React, { useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import useInformation from "../Hooks/useInformation";

const Project = () => {
  const [info, isLoading] = useInformation();
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleExpansion = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };
  return (
    <div>
      <div className="py-12">
        <div className="xl:container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="mb-12 space-y-2 text-center">
            <h2 className="text-3xl font-bold text-gray-800 md:text-4xl dark:text-white ">
              Projects
            </h2>
          </div>
          {isLoading === true ? (
            <div className="flex justify-center my-20">
              <CirclesWithBar
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          ) : (
            <div className="flex flex-wrap justify-center gap-5">
              {info
                .filter((item) => item.role === "projects")
                .map((item, index) => (
                  <div
                    className="group w-96  p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10"
                    key={index}
                  >
                    <div className="relative overflow-hidden rounded-xl">
                      <img
                        src={item.imageUrl}
                        alt="art cover"
                        loading="lazy"
                        width="1000"
                        height="667"
                        className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                      />
                    </div>
                    <div className="mt-6 relative">
                      <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
                        {item?.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300 group-hover:text-lg transition-all duration-300">
                        {expandedIndexes.includes(index)
                          ? item?.details
                          : item?.details.slice(0, 400)}
                      </p>
                      <button
                        className="text-lg dark:text-violet-500 font-bold mt-2"
                        onClick={() => toggleExpansion(index)}
                      >
                        {expandedIndexes.includes(index) ? "Less" : "More"}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
