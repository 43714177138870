import React from "react";
import management from "../../Assets/image/management.png";
const Organugram = () => {
  return (
    <div className="min-h-screen ">
    <p className="font-extrabold text-2xl text-center my-5">
      <span className="border-2 border-cyan-600 p-2 rounded-full text-transparent bg-clip-text bg-gradient-to-bl from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500">
        Our Organugram
      </span>
    </p>

    <div className="w-10/12 h-10/12 xl:w-1/3 mx-auto mt-10 p-8  rounded-lg bg-gradient-to-r from-purple-400 via-pink-400 to-blue-500 hover:from-orange-500 hover:via-green-400 hover:to-teal-600">
      <label htmlFor="my-modal-6" className="relative">
        <img className="m-auto h-auto w-auto   " src={management} alt="" />
      </label>
    </div>

    <input type="checkbox" id="my-modal-6" className="modal-toggle" />
    <div className="modal modal-bottom sm:modal-middle overflow-y-scroll">
      <div className="w-full h-full">
        <div className="flex justify-end">
          <label htmlFor="my-modal-6" className="btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </label>
        </div>
        <img
          className="overflow-x-hidden w-auto  m-auto  rounded-lg  cursor-pointer z-10"
          src={management}
          alt={management}
        />
      </div>
    </div>
  </div>
  );
};

export default Organugram;
