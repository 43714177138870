import React from "react";
import image from "../../Assets/image/main_image_2.JPG";

const Content = () => {
  return (
    <div className="bg-slate-50 dark:bg-gray-800">
      <section className="flex items-center justify-center flex-wrap gap-12 lg:gap-20 mt-5 py-5">
        <div
          data-aos="fade-right"
          data-aos-duration="1500"
          className=" overflow-hidden rounded-full border-4 border-green-400"
        >
          <img
            className="w-96 h-96 hover:scale-125 transition-transform ease-in-out duration-200 cursor-pointer "
            src={image}
            alt=""
          />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1500"
          className={"xl:max-w-xl max-w-xs text-start"}
        >
          <div className="flex items-center py-3 gap-4 dark:text-slate-50">
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/osbjlbsb.json"
                colors="primary:#ee66aa,secondary:#ee8f66"
                stroke="75"
                trigger="hover"
                style={{ width: "80px", height: "65px" }}
              ></lord-icon>
              <p className="text-xl font-bold text-center">Vision</p>
            </div>
            <p className=" font-semibold">
              A world with equity where every potential is empowered to create
              scalable impact for a sustainable future
            </p>
          </div>
          <div className="flex items-center py-3 gap-4 dark:text-slate-50">
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/gowvgfsi.json"
                colors="primary:#ee66aa,secondary:#ee8f66"
                stroke="75"
                trigger="morph"
                style={{ width: "80px", height: "65px" }}
              ></lord-icon>
              <p className="text-xl font-bold text-center ">Mission</p>
            </div>
            <p className=" font-semibold ">
              We enable people to realize their own potentials through capacity
              enhancement, market connect and financial choices in the landscape
              of ever-evolving opportunities
            </p>
          </div>
          <div className="flex items-center py-3 gap-4 dark:text-slate-50">
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/hbwqfgcf.json"
                colors="primary:#ee66aa,secondary:#ee8f66"
                stroke="75"
                trigger="hover"
                style={{ width: "80px", height: "65px" }}
              ></lord-icon>
              <p className="text-xl font-bold text-center ">Values</p>
            </div>
            <ul className=" font-semibold ">
              <li>Momentum for Members</li>
              <li>Action is Adventurous</li>
              <li>Innovation should be infectious</li>
              <li>Growth is Glorious</li>
              <li>Rejoice in Responsibility</li>
              <li>Inspiration in Integrity</li>
            </ul>
          </div>
        </div>
      </section>
      <hr class="h-px my-8 bg-gray-300 border-0 dark:bg-gray-700"></hr>
    </div>
  );
};

export default Content;
