import React from "react";
import { useLocation } from "react-router-dom";

const CardDetails = (props) => {
  const location = useLocation();
  const item = location.state?.item;
  console.log("🚀 ~ file: CardDetails.jsx:7 ~ CardDetails ~ item:", item);
  return (
    <div>
      <section class="text-gray-600 dark:bg-gray-900 body-font overflow-hidden">
        <div class="container px-5 py-24 mx-auto">
          <div class="lg:w-4/5 mx-auto flex flex-wrap">
            <img
              alt="APPLE iPhone 11 (White, 128 GB)"
              class="lg:w-1/2 w-full lg:h-96 h-64 object-contain object-center rounded"
              src={item?.imageUrl}
            />
            <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 class="text-gray-900 dark:text-white text-3xl title-font font-medium mb-1">
                {item?.title}
              </h1>

              <p class="leading-relaxed dark:text-gray-300">{item?.details}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CardDetails;
