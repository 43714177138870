import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "./Carousel.css";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import useInformation from "../Hooks/useInformation";
import { Image, Modal } from "@nextui-org/react";

const Carousel = () => {
  const [info] = useInformation();
  const [image, setImage] = useState([]);
  const [visible, setVisible] = useState(false);
  const closeHandler = () => {
    setImage("");
    setVisible(false);
  };
  return (
    <div>
      <Swiper
        effect={"coverflow"}
        loop={true}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[Autoplay, EffectCoverflow, Pagination]}
        className="mySwiper mt-6"
      >
        {info
          .filter((item) => item?.role === "carousel")
          .map((item, index) => (
            <SwiperSlide key={index} className="hover:cursor-pointer">
              <img
                onClick={() => {
                  setImage(item?.imageUrl);
                  setVisible(true);
                }}
                src={item?.imageUrl}
                alt=""
              />
            </SwiperSlide>
          ))}

        <SwiperSlide>
          <img
            src="https://i.ibb.co/pQFbdpK/5p-HJ6d5-Q0-VEDZDn-Npsc-II1-Blfku5-UQw-Wdp-LDzovb.jpg"
            alt=""
          />
        </SwiperSlide>
      </Swiper>

      <Modal
        noPadding
        blur
        open={visible}
        onClose={closeHandler}
        style={{ backgroundColor: "transparent" }}
      >
        <Modal.Header
          css={{
            position: "absolute",
            zIndex: "$1",
            top: 5,
            right: 8,
          }}
        >
          <button className="bg-none" size="sm" onClick={closeHandler}>
            <div className="flex">
              <p>Close</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Image src={image} width="100%" height="95%" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Carousel;
