import React from "react";
import pic1 from "../../Assets/image/chairman.jpg";
// import pic2 from "../../Assets/image/director.jpg";
import { Link } from "react-router-dom";
const Contributor = () => {
  return (
    <>
      <div className="flex flex-wrap justify-center dark:bg-gray-800 my-5 gap-y-5 gap-x-16 mx-3">
        {/* card1  */}
        <div>
          <p className="dark:text-slate-50 text-center text-lg font-semibold mb-2">
            Chairman Speech
          </p>
          <div className="group relative rounded-3xl  space-y-6 overflow-hidden xl:w-96 xl:h-96 md:w-80 md:h-80 border border-gray-800 dark:border-cyan-300">
            <img
              className="mx-auto h-[26rem] w-11/12 grayscale-0 object-contain object-top transition duration-500 group-hover:scale-105 group-hover:grayscale"
              src={pic1}
              alt="woman"
              loading="lazy"
              width="640"
              height="805"
            />
            <div className="absolute bottom-0 inset-x-0 h-max mt-auto px-8 py-6 dark:bg-gray-800 bg-white translate-y-24 transition duration-300 ease-in-out group-hover:translate-y-0">
              <div>
                <h4 className="text-xl font-semibold text-gray-700 dark:text-white">
                  Habibur Rahman
                </h4>
                <span className="block text-sm text-gray-500">Chairman</span>
              </div>
              <p className="mt-8 dark:text-gray-300 text-gray-600">
                <strong>Polliprottasha</strong> is a socio-economic organization
                founded in 2003 to ra . . . .<br></br>
                <Link to="/chairman" className="text-bold text-violet-400">
                  Read More
                </Link>
              </p>
            </div>
          </div>
        </div>
        {/* card2  */}
        {/* <div>
          <p className="dark:text-slate-50 text-center text-lg font-semibold mb-2">
            Director Speech
          </p>
          <div className="group relative rounded-3xl  space-y-6 overflow-hidden xl:w-96 xl:h-96 md:w-80 md:h-80 border border-gray-800 dark:border-cyan-300">
            <img
              className="mx-auto h-[26rem] w-11/12 grayscale-0 object-contain object-top transition duration-500 group-hover:scale-105 group-hover:grayscale  "
              src={pic2}
              alt="woman"
              loading="lazy"
              width="640"
              height="805"
            />
            <div className="absolute bottom-0 inset-x-0 h-max mt-auto px-8 py-6 dark:bg-gray-800 bg-white translate-y-24 transition duration-300 ease-in-out group-hover:translate-y-0">
              <div>
                <h4 className="text-xl font-semibold text-gray-700 dark:text-white">
                  Sheikh Faruque ahammad
                </h4>
                <span className="block text-sm text-gray-500">
                  Executive Director
                </span>
              </div>
              <p className="mt-8 dark:text-gray-300 text-gray-600">
                <strong>Sheikh Faruque ahammad</strong> has been working in the
                development field for . . . .<br></br>
                <Link to="/director" className="text-bold text-violet-400">
                  Read More
                </Link>
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Contributor;
