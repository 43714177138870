import React from "react";
import hero_image from "../../Assets/image/Hero.jpg";

const Hero = () => {
  
  return (
    <div className=" w-full border-gray-900">
      <div className="relative h-screen ">
        <img
          className=" object-cover h-full w-full dark:brightness-75"
          src={hero_image}
          alt={hero_image}
        />
        <div
          data-aos="fade-right"
          data-aos-duration="1500"
          className="absolute inset-0  container m-auto px-6 py-40 md:px-12 lg:py-0 lg:px-7 "
        >
          <div className="flex  flex-wrap gap-12 lg:gap-0 py-5 -mt-32 md:-mt-0">
            <div className="lg:w-5/12 space-y-8">
              <span className="flex space-x-2">
                <span className="block w-14 mb-2 border-b-2 border-gray-600"></span>
                <span className="font-medium text-gray-600 dark:text-gray-400">
                  New Ambition
                </span>
              </span>
              <div className=" font-bold  dark:text-white">
                <p className="text-4xl md:text-6xl">Polliprottasha </p> <br />
                <p className="text-xl -mt-6 md:text-2xl">
                  A Socio-Economic Organization
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="h-px my-8 bg-gray-300 border-0 dark:bg-gray-700"></hr>
    </div>
  );
};

export default Hero;
