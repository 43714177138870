import React, { useEffect, useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import useInformation from "../Hooks/useInformation";
import "swiper/css";
import "swiper/css/effect-cards";
import { Autoplay, EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Success = () => {
  const [info, isLoading] = useInformation();
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleExpansion = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };
  const [link, setLink] = useState([]);
  console.log("🚀 ~ file: Success.jsx:17 ~ Success ~ link:", link);

  useEffect(() => {
    fetch(
      `https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCJexqogkyMYApS3SIW1wtcA&maxResults=10&order=date&key=AIzaSyCAV3npS45wknFEyW2eKudLDWK4eWrDhmo`
    )
      .then((res) => res.json())
      .then((data) => {
        setLink(data);
      });
  }, []);

  return (
    <div className="bg-slate-50 dark:bg-gray-800 mt-4 py-5 ">
      <p className="dark:text-green-600 text-center font-extrabold text-3xl mb-4 ">
        Success Story
      </p>
      {isLoading === true ? (
        <div className="flex justify-center my-20">
          <CirclesWithBar
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      ) : (
        <div className="flex flex-wrap justify-center  gap-4 ">
          {info
            .filter((item) => item.role === "success")
            .slice(-3, -1)
            .map((item, index) => (
              <div
                key={index}
                className="max-w-sm bg-white border border-gray-800 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-200 mx-3 hover:cursor-pointer"
              >
                <p>
                  <img
                    style={{ width: "100%", height: "320px" }}
                    className="rounded-t-lg"
                    src={item.imageUrl}
                    alt=""
                  />
                </p>
                <div className="p-5">
                  <p>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {item?.title}
                    </h5>
                  </p>
                  <p className="text-gray-600 dark:text-gray-300 group-hover:text-lg transition-all duration-300">
                    {expandedIndexes.includes(index)
                      ? item?.details
                      : item?.details.slice(0, 400)}
                  </p>
                  <button
                    className="text-lg dark:text-violet-500 font-bold mt-2"
                    onClick={() => toggleExpansion(index)}
                  >
                    {expandedIndexes.includes(index) ? "Less" : "More"}
                  </button>
                </div>
              </div>
            ))}
          <div
            style={{
              width: "370px",
              height: "465px",
            }}
            className="flex flex-col justify-center items-center"
          >
            <p className="dark:text-green-600 text-center font-extrabold text-3xl md:mt-4 xl:-mt-14">
              Documentary
            </p>
            <Swiper
              style={{
                width: "280px",
                height: "465px",
              }}
              effect={"cards"}
              grabCursor={true}
              loop={true}
              modules={[Autoplay, EffectCards]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="mySwiper"
            >
              {link?.items?.map((item, index) => (
                <SwiperSlide
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "18px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                  key={index}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://youtu.be/${item?.id?.videoId}`}
                  >
                    {" "}
                    <img
                      src={item?.snippet?.thumbnails?.high?.url}
                      alt={item?.title}
                    />
                  </a>
                </SwiperSlide>
              ))}
              <SwiperSlide>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/watch?v=NHVKqLTUolI"
                >
                  {" "}
                  <img
                    src="https://i.ytimg.com/vi/NHVKqLTUolI/hqdefault.jpg"
                    alt="https://i.ytimg.com/vi/NHVKqLTUolI/hqdefault.jpg"
                  />
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default Success;
